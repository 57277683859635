import React, { useState } from 'react';
import { connect } from 'react-redux'

import ReactHtmlParser from 'react-html-parser';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Grid, Hidden, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { readVortrag } from '../../actions/fpt';
import { Link } from 'react-router-dom';
import EcoIcon from '@material-ui/icons/Eco';


const FptProgrammItem = (props) => {

    const [details, setDetails] = useState({ abstract: props.data.type == 'vortrag' ? 'loading...' : '' });
    const [expanded, setExpanded] = useState(false);

    const readDetails = (idVortrag) => {
        props.dispatch(readVortrag(idVortrag, onDetailsRead))
    }

    const onDetailsRead = (data) => {
        console.log(data);
        setDetails(data);
    }

    const onAccordionChange = (id) => (event, isExpanded) => {
        if (props.data.type == 'kontaktmesse' || props.data.type == 'meetthepress') return;

        setExpanded(isExpanded);
        isExpanded && props.data.type == 'vortrag' && readDetails(id);
    }

    const displayTag = (tagin) => {
        const tag = tagin.trim();

        const mappings = {
            netzwerk: {
                style: { backgroundColor: '#555', color: '#fff' },
                label: 'Netzwerk',
                bg: "BadgeNetzwerk"
            },
            process: {
                style: { backgroundColor: '#3080ee', color: '#fff' },
                label: 'Prozessautomation',
                bg: "BadgeProzessautomation"
            },
            sicher: {
                style: { backgroundColor: '#fed052', },
                label: 'Sicherheit',
                bg: "BadgeSicherheit"
            },
            green: {
                style: { backgroundColor: 'green', color: '#fff' },
                label: 'Green',
                bg: "BadgeGreen"
            },
            robotik: {
                style: { backgroundColor: 'rgb(25, 37, 194)', color: '#fff' },
                label: 'Robotik',
                bg: "BadgeRobotik"
            },
            control: {
                style: { backgroundColor: 'rgb(255 24 0)', color: '#fff' },
                label: 'Steuerung',
                bg: "BadgeSteuerung"
            },
            ki: {
                style: { backgroundColor: 'rgb(255 146 0)', color: '#fff' },
                label: 'KI in Automation',
                bg: "BadgeKI"
            },
            maschinenbau: {
                style: { backgroundColor: 'rgb(255 146 0)', color: '#fff' },
                label: 'Machinenbau',
                bg: "BadgeKI"
            },
            sensor: {
                style: { backgroundColor: '#49B77B', color: '#fff' },
                label: 'Sensorik',
                bg: "BadgeSensorik"
            },
            mess: {
                style: { backgroundColor: '#49B77B', color: '#fff' },
                label: 'Messtechnik',
                bg: "BadgeSensorik"
            },
            digital: {
                style: { backgroundColor: 'rgb(184, 140, 20)', color: '#fff' },
                label: 'Digitalisierung',
                bg: "BadgeDigital"
            },
            IoT: {
                style: { backgroundColor: 'rgb(47, 141, 68)', color: '#fff' },
                label: 'IoT',
                bg: "BadgeIoT"
            },
        }

        if (tag == 'green') {
            return (
                <EcoIcon style={{ color: '#85b72a' }} />
            )
        }

        return (
            mappings[tag] && <Chip size="small" style={mappings[tag].style} label={mappings[tag].label} />
        )
    }


    return (
        <Accordion
            expanded={expanded}
            style={{ backgroundColor: props.data.type == 'kontaktmesse' && '#eee' }}
            onChange={onAccordionChange(props.data.id)}
        >

            <AccordionSummary expandIcon={props.data.type == 'vortrag' && <ExpandMoreIcon />}>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={1}>
                        {props.data.zeit}
                    </Grid>
                    <Grid item xs={10} md={9}>
                        <Typography variant='body1' style={{ fontWeight: '600' }}>
                            {props.data.firma}
                        </Typography>
                        {props.data.id == 2044 &&
                            <Typography variant='body1' style={{ fontWeight: '600' }}>
                                ARNOLD UMFORMTECHNIK GmbH & Co. KG
                            </Typography>
                        }
                        <Typography variant='body2'>
                            {props.data.referent}
                            {props.data.referent2 && ", " + props.data.referent2}
                        </Typography>
                        {props.data.type == 'meetthepress' ?
                            <Typography variant='body1'>
                                {ReactHtmlParser(props.data.content)}
                            </Typography>
                            :
                            <Typography variant='body1' style={{ color: props.data.type == 'vortrag' && '#ff6600', fontWeight: '600' }}>
                                {ReactHtmlParser(props.data.content)}
                            </Typography>
                        }

                    </Grid>
                    <Hidden smDown>
                        <Grid item md={2}>
                            {props.data.tags && props.data.tags.split(',').map(tag =>
                                <div key={tag} style={{ marginBottom: '2px' }}>
                                    {displayTag(tag)}
                                </div>
                            )}
                        </Grid>
                    </Hidden>
                </Grid>
            </AccordionSummary>

            {props.data.type == 'vortrag' &&
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={2} md={1}>
                        </Grid>
                        <Grid item xs={10} md={11}>
                            <Typography variant='body1'>
                                {ReactHtmlParser(details.abstract)}
                            </Typography>


                            {props.data.id == 2044 ?
                                <div>
                                    <Link to={'thema/' + props.data.id}>
                                        <Button style={{ marginTop: '12px' }} variant="contained" color="primary">
                                            Details {props.data.firma}
                                        </Button>
                                    </Link>
                                    <br/>
                                    <Link to={'thema/2008'}>
                                        <Button style={{ marginTop: '12px' }} variant="contained" color="primary">
                                            Details ARNOLD UMFORMTECHNIK
                                        </Button>
                                    </Link>
                                </div>
                                :
                                <Link to={'thema/' + props.data.id}>
                                    <Button style={{ marginTop: '12px' }} variant="contained" color="primary">
                                        Details &amp; download...
                                    </Button>
                                </Link>
                            }

                        </Grid>
                    </Grid>
                </AccordionDetails>
            }

        </Accordion>

    )

}

export default connect()(FptProgrammItem);