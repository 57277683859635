import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { Alert } from '@material-ui/lab';
import FptBewertungThemen from '../generic/FptBewertungThemen';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import IconYes from '@material-ui/icons/CheckCircleOutline';
import IconEnthaltung from '@material-ui/icons/RemoveCircleOutline';
import IconNo from '@material-ui/icons/CancelOutlined';
import Typography from '@material-ui/core/Typography';

import { startReadBewertungErgebnis, startReadMeineBewertungen, startReadThemenliste } from '../../../actions/fpt';
import { config } from '../../../config/config';
import ErgebnisChart from '../../utils/ErgebnisChart';
import { jsonDateToDisplay } from '../../../helper/dateHelper';


const idFpt = "fpt036";
const titel = 'Fachpressetage 2025 - Vorträge bewerten';

const PageBewertung = (props) => {

    useEffect(() => {
        !props.fpt[idFpt].meineBewertungen && loadBewertungen();
        !props.fpt[idFpt].themen && loadThemen();
        loadWahlergebnis(idFpt);
    }, []);

    const loadBewertungen = () => {
        props.dispatch(startReadMeineBewertungen(props.user.idFptProfilPerson, idFpt));
    }

    const loadThemen = () => {
        props.dispatch(startReadThemenliste(idFpt))
    }

    const loadWahlergebnis = (idFpt) => {
        props.dispatch(startReadBewertungErgebnis(idFpt));
    }


    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung ablaufdatum={config[idFpt].wahlEnde} idFpt={idFpt} fptTitel={titel}>

                <Grid container spacing={2}>

                    {config[idFpt].votingOpen ?
                        <Grid item xs={12} md={10} lg={8} xl={6}>
                            <Alert severity="info">
                                Entscheiden Sie mit, welche Vorträge ins Programm aufgenommen werden sollen.
                                <br />
                                <IconNo fontSize="small" /> soll nicht ins Programm<br />
                                <IconEnthaltung fontSize="small" /> Enthaltung (z.B. nicht mein Themengebiet)<br />
                                <IconYes fontSize="small" /> soll ins Programm<br />
                                <br />
                                Klicken Sie auf den jeweiligen Titel, um alle Details (+ Abstract) zu sehen und einen Kommentar einzutragen.
                            </Alert>
                            <FptBewertungThemen idFpt={idFpt} />
                        </Grid>
                        :
                        <Grid item xs={12} md={10} lg={8} xl={6}>
                            <div style={{ display: 'table', margin: '0 auto', paddingBottom: '20px', paddingTop: '10px' }}>
                                {!config[idFpt].ergebnisAnzeigen &&
                                    <img src="/images/closed.png" />
                                }
                            </div>
                            <Typography variant='body2' gutterBottom>
                                Das Wahllokal ist geschlossen. Alle Stimmen, die bis zum {jsonDateToDisplay(config[idFpt].wahlEnde)} abgegeben wurden, werden gezählt!
                            </Typography>


                            {config[idFpt].ergebnisAnzeigen &&
                                <React.Fragment>
                                    <Typography variant='h4' gutterBottom>
                                        Wahlergebnis
                                    </Typography>                                    
                                    <ErgebnisChart filter={[2008]} wahlergebnis={props.fpt[idFpt].wahlergebnis} />
                                    <Typography variant='body2' gutterBottom>
                                        Hinweis: Aus Gründen der Diskretion sehen Sie abgelehnte Themen nicht in der Liste
                                    </Typography>
                                </React.Fragment>
                            }
                        </Grid>

                    }
                </Grid>

            </FptForceAnmeldung>

        </React.Fragment>
    )


}


const mapStateToProps = state => ({
    user: state.global.user,
    fpt: state.fpt
});

export default connect(mapStateToProps)(PageBewertung);
