import React from 'react';
import { connect } from 'react-redux'

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Chip } from '@material-ui/core';



const FptCard = (props) => {

    return (
        <Card variant="outlined" style={props.style}>
            <CardContent style={props.contentStyle}>
                {props.chipLabel &&
                    <div style={{height: '30px'}}>
                        <Chip
                            style={{ float: 'right' }}
                            size="small"
                            label={props.chipLabel}
                            color={props.chipColor}
                        />
                    </div>
                }
                <Typography variant="h5" component="h2" gutterBottom>
                    {props.title}
                </Typography>

                <div>
                    {props.children}
                </div>

            </CardContent>

            <CardActions style={{ padding: '0px 16px 16px 16px' }}>
                {props.buttons}
            </CardActions>
        </Card>
    )
}


export default FptCard;
