import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { startReadThemenliste } from '../../../actions/fpt';
import { logoUri } from '../../../config/globals';
import { Button, Grid, Typography } from '@material-ui/core';
import FptCard from '../../utils/FptCard';


const FptTische = (props) => {

    const history = useHistory();

    useEffect(() => {
        startLoadThemen();
    }, []);

	const openThema = (id) => {
		history.push(`/${props.idFpt}/thema/${id}`);
	}

    const startLoadThemen = () => {
        props.dispatch(startReadThemenliste(props.idFpt));
    }

    return (
        <div>
            <Grid container spacing={2}>
                {props.themen && props.themen
                    .filter(thema => thema.idFptVeranstaltungsblock === 3)
                    .sort((a, b) => (a.firmenname.toUpperCase() < b.firmenname.toUpperCase() ? -1 : 1))
                    .map(thema =>
                        <Grid key={thema.idFptThema} item xs={12} md={8} lg={6} xl={4}>

                            <FptCard
                                contentStyle={{minHeight: '190px'}}
                                buttons={
                                    <Button
                                        onClick={() => openThema(thema.idFptThema)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Details
                                    </Button>
                                }
                                title={
                                    <div>
                                        <div>
                                            <img style={{ maxWidth: '150px', maxHeight:'60px' }} src={thema.logoFile && logoUri + thema.logoFile + "?v=" + (new Date()).getTime()} />
                                        </div>
                                        <div>
                                            {thema.firmenname}
                                        </div>
                                    </div>
                                }
                            >
                                <div>
                                    <Typography variant="subtitle1">
                                        {thema.titel}
                                    </Typography>
                                </div>
                            </FptCard>
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
}

const mapStateToProps = (state, propsExt) => ({
    themen: state.fpt[propsExt.idFpt].themen,
});

export default connect(mapStateToProps)(FptTische);