import React from 'react';
import { useHistory } from 'react-router-dom'

import { Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import { config } from '../../config/config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@material-ui/icons/Assignment';

const ErgebnisChart = (props) => {

    const history = useHistory();

    const showDetail = (idThema) => {
        console.log(idThema);
        history.push('/' + config.currentFpt + '/thema/' + idThema);
    }

    const newLine = "\r\n";

    return (
        <div style={{ width: '600px' }}>

            {props.wahlergebnis
                .filter(record => props.filter && !props.filter.includes(record.idFptThema))
                .map((record) =>
                    <Tooltip
                        key={record.idFptThema}
                        title={
                            <div>
                                <Typography variant='h6' gutterBottom>
                                    {record.firmenname}
                                </Typography>
                                <Typography variant='body2' gutterBottom>
                                    {record.titel}
                                </Typography>
                            </div>
                        }>
                        <span>
                            <button
                                style={{
                                    textAlign: 'left',
                                    border: 'none',
                                    height: '42px',
                                    marginBottom: '12px',
                                    paddingLeft: '5px',
                                    paddingTop: '5px',
                                    borderRadius: '5px',
                                    width: (record.zustimmungProzent - 10) + '%',
                                    backgroundColor: '#4d77cf',
                                    color: '#fff'
                                }}
                                onClick={() => showDetail(record.idFptThema)}
                            >
                                <Typography variant='button'>
                                    {record.firmenname.length > 25 ? record.firmenname.substring(0, 25) + "..." : record.firmenname} - {record.zustimmungProzent} %
                                </Typography>
                            </button>
                            <CopyToClipboard 
                                text={record.firmenname + " (" + record.zustimmungProzent + ' %)' + newLine + record.titel }
                            >
                                <IconButton>
                                    <ContentCopyIcon />
                                </IconButton>
                            </CopyToClipboard>
                        </span>
                    </Tooltip>

                )}

        </div>
    )

}





export default ErgebnisChart;