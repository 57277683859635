import { Button, Card, CardActions, CardContent, Grid, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { sendAutoMail, startSignUpUser } from "../../actions/global";
import LoginControl from "../utils/LoginControl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PageRedakteur = (props) => {

    const history = useHistory();

    useEffect(() => {
        if (props.global.user) {
            history.push('/');
        }
    }, [props.global.user]);

    const [formData, setFormData] = useState({});
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const txtInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        setErrorMessage(undefined);
    }

    const bttnSubmitClick = () => {
        if (checkForm()) {
            const daten = {
                name: formData.name,
                redaktion: formData.redaktion,
                email: formData.email
            };
            props.dispatch(sendAutoMail(4, "ahomburg@rbsonline.de", daten, onSuccess, onFail))
        }
    }

    const onSuccess = (result) => {
        result ? setSuccessMessage('Vielen Dank für Ihre Anmeldung. Wir werden einen Login erstellen und Sie erhalten die Zugangsdaten per Mail. Bitte haben Sie etwas Geduld, wir prüfen Ihre Daten manuell, weil nur Journalisten Zugriff erhalten.') :
            setErrorMessage("Sorry, da ist leider etwas schief gegangen, bitte schicken Sie eine Mail an: ioehlschlaeger@rbsonline.de");
    }

    const onFail = (error) => {
        setErrorMessage("Sorry, da ist leider etwas schief gegangen, bitte schicken Sie eine Mail an: ioehlschlaeger@rbsonline.de");
    }

    const checkForm = () => {
        if (formData.name && formData.redaktion && formData.email) {
            return true;
        }
        else {
            setErrorMessage("Bitte füllen Sie alle Felder aus!");
            return false;
        }
    }

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12}>

                    <Typography variant="h4" gutterBottom>
                        Als Redakteur:in anmelden
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                        Hier können Sie Sich anmelden. Wenn Sie noch kleinen persönlichen Login haben,
                        erstellen wir einen für Sie.
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={() => window.open('https://www.fachpressetage.de/Fachpressetage2025/', '_blank')}
                        variant="contained"
                    >
                        Programm 2025
                    </Button>
                    <br /><br /><br />

                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} lg={4} lx={4}>
                            <Card elevation={10} style={{ height: '430px', borderRadius: '15px' }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Hier einloggen und anmelden
                                    </Typography>
                                    <LoginControl hideForgotPwd />
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item xs={12} md={6} lg={5} xl={4}>
                            <Card elevation={10} style={{ backgroundColor: '#efeeee', borderRadius: '15px' }}>
                                <CardContent style={{ height: '430px', }}>
                                    <Typography variant="h5" gutterBottom>
                                        Ich habe noch keinen Login
                                    </Typography>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{ backgroundColor: '#fff' }}
                                                autoFocus={true}
                                                fullWidth
                                                margin="normal"
                                                name="name"
                                                label="Name"
                                                variant="outlined"
                                                value={formData.name}
                                                onChange={txtInputChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                style={{ backgroundColor: '#fff' }}
                                                fullWidth
                                                margin="normal"
                                                name="redaktion"
                                                label="Redaktion"
                                                variant="outlined"
                                                value={formData.redaktion}
                                                onChange={txtInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{ backgroundColor: '#fff' }}
                                                fullWidth
                                                margin="normal"
                                                name="email"
                                                label="E-Mail"
                                                variant="outlined"
                                                value={formData.email}
                                                onChange={txtInputChange}
                                            />
                                        </Grid>
                                    </Grid>

                                    {successMessage && <div><Alert severity="success">{successMessage}</Alert></div>}
                                    {errorMessage && <div style={{ height: '55px' }} item xs={12}><Alert severity="error">{errorMessage}</Alert></div>}

                                    {!errorMessage && !successMessage &&
                                        <div style={{ height: '55px' }}>&nbsp;</div>
                                    }

                                    {!successMessage &&
                                        <Button
                                            onClick={bttnSubmitClick}
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                        >
                                            Anmelden
                                        </Button>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </div>
    );
}



const mapStateToProps = (state) => {
    return {
        global: state.global
    }
};

export default connect(mapStateToProps)(PageRedakteur);