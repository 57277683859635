import React from 'react';

import Divider from '@material-ui/core/Divider'
import FptCard from './FptCard';
import FptLink from './FptLink';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


/**
 * Zeigt eine Themenseite an
 * 
 * @param {object} props Component props
 * @param {object} props.firma (required)
 */
const FptFirmaCard = (props) => {


    return (
        <FptCard title={props.profilFirma && props.profilFirma.firmenname}>

            {props.profilFirma && props.profilFirma.adresszusatz &&
                <Typography variant="body1">
                    {props.profilFirma.adresszusatz}
                </Typography>
            }

            <Typography variant="body1">
                {props.profilFirma && props.profilFirma.strasse}
            </Typography>
            <Typography variant="body1">
                {props.profilFirma && props.profilFirma.idLand + "-" + props.profilFirma.plzStrasse + props.profilFirma.ort}
            </Typography>
            {props.profilFirma && props.profilFirma.www &&
                <FptLink href={props.profilFirma.www}>
                    {props.profilFirma.www}
                </FptLink>
            }

            <Divider style={{ marginTop: '16px', marginBottom: '24px' }} />

            <Grid container spacing={2}>
                <Grid item xs={3}>
                    Themen:
                </Grid>
                <Grid item xs={9}>
                    {props.profilFirma && props.profilFirma.themen}
                </Grid>

                <Grid item xs={3}>
                    Biete:
                </Grid>
                <Grid item xs={9}>
                    {props.profilFirma && props.profilFirma.biete}
                </Grid>

                <Grid item xs={3}>
                    Suche:
                </Grid>
                <Grid item xs={9}>
                    {props.profilFirma && props.profilFirma.suche}
                </Grid>
            </Grid>

        </FptCard>
    )

}


export default FptFirmaCard